import { createReducer, on } from '@ngrx/store';
import { ITelegramState } from './model';
import {
  AddTelegramUsers,
  SetTelegramSelectedUser,
  AddSelectedMessages,
  AddMessage,
  SetMessageReaded,
  SetZeroPendingUserMessages,
} from './actions';
import { TELEGRAM_MESSAGE_STATE } from '@libs/interfaces';

const initialState: Readonly<ITelegramState> = {
  users: [],
  user: null,
  messages: [],
};
export const telegramReducer = createReducer(
  initialState,
  on(AddTelegramUsers, (state, { users }) => ({ ...state, users })),
  on(SetTelegramSelectedUser, (state, { user }) => ({ ...state, user })),
  on(AddSelectedMessages, (state, { messages }) => ({ ...state, messages })),
  on(AddMessage, (state, { message }) => {
    if (+state.user?.telegram_id === +message.message.chat.id) {
      const messages = [...state.messages, message];
      return { ...state, messages: [...state.messages, message] };
    }
    return state;
  }),
  on(SetMessageReaded, (state, { _id }) => {
    const messages = state.messages.map(message => {
      if (message._id === _id) {
        return { ...message, state: TELEGRAM_MESSAGE_STATE.READED };
      }
      return message;
    });
    return { ...state, messages };
  }),
  on(SetZeroPendingUserMessages, (state, { user_id }) => {
    const users = state.users.map(user => {
      if (user.user_id === user_id) {
        return { ...user, num_pending_messages: 0 };
      }
      return user;
    });
    return { ...state, users };
  }),
);
